import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.css"]
})
export class CounterComponent implements OnInit {
  public days;
  public hours;
  public minutes;

  constructor() {}

  ngOnInit() {
    setInterval(() => {
      this.get(); 
    }, 1000);
  }

  get(){
    let count_down = new Date('2022-04-15 00:00:00').getTime();
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;

    let now = new Date(Date.now()).getTime();
    let diff = count_down - now;

    this.days = Math.floor(diff / day);
    this.hours = Math.floor(diff % day / hour);
    this.minutes = Math.floor(diff % hour / minute);
  }
}
