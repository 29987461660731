import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ClientsService } from "../../clients/clients.service";
import { LeadService } from "../../lead/lead.service";
import { TeamService } from "../../team/team.service";
import { MaskService } from "../../util/mask.service";
import { ValidatorsService } from "../../util/validators.service";
import { ShareService } from "../../share/share.service";
import { PackagePriceService } from "../../package_price/package_price.service";
import { UtilService } from "src/app/util/util.service";

declare const $: any;

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  public message: any;
  title = "app";

  createClient: FormGroup;
  createPayment: FormGroup;

  public fcpf: any = false;
  public lead: any;
  public customName: number;
  public customNumber: any;
  public teams: any;
  public playerName: any;
  public position: any;
  public position_id: any;
  public team_id: any;
  public numberOk = 0;
  public position_name: any;
  public team_name: any;
  public liberabotao = 0;
  public gameId: any;
  public teamSide: any;
  public gift = 0;
  public gameHour: any;
  public playerSoccer: any;
  public playerSoccer2: any;
  // compra de várias posições
  public quantity: any;
  public packagePlayer = [];
  public packagePurchase = [];
  public formType = 0;
  public purchaseButton = 0;
  public partial_value: any;
  public totalValue: any;
  public positionValue: any;
  public position_value: any;
  public position_value_modal: any;
  public goal_keeper_value: any;
  public goal_keeper_total: any;
  public line_player_value: any;
  // compra de várias posições
  public reserved_position: any = [];
  public table = "discount";
  public block_cupon = 0;
  public age = 16;
  public date: any;

  constructor(
    private clientService: ClientsService,
    private leadService: LeadService,
    private formBuilder: FormBuilder,
    private teamService: TeamService,
    private maskService: MaskService,
    private validatorsService: ValidatorsService,
    private shareService: ShareService,
    private packagePriceService: PackagePriceService,
    public utilService: UtilService
  ) {
    let dNow = new Date();
    this.date =
      dNow.getFullYear() +
      "-" +
      (dNow.getMonth() + 1 < 10 ? "0" : "") +
      (dNow.getMonth() + 1) +
      "-" +
      (dNow.getDate() < 10 ? "0" : "") +
      dNow.getDate();
  }

  ngOnInit() {
    this.gift = 0;
    this.numberOk = 1;
    this.takePackagePlayer();
  }
  // Pega o pacote de posições selecionadas para compra do service
  takePackagePlayer() {
    this.shareService.sharePackagePlayer.subscribe((res) => {
      this.packagePlayer = res;
      if (this.packagePlayer.length == 1) {
        this.setPlayerConfig(this.packagePlayer[0]);
      }
    });
  }
  olhaso(event) {
    let tabs = Array.from(document.querySelectorAll(".tab_camisa"));
    tabs.forEach((tab) => tab.classList.remove("activeTab"));
    event.currentTarget.classList.add("activeTab");
  }
  /*================================================================================----- FORMULÁRIOS */
  createFormClient() {
    this.createClient = this.formBuilder.group({
      name: [""],
      email: [""],
      ddd: [""],
      phone: [""],
      documentNumber: [""],
      born_at: [""],
      shirt_name: ["", Validators.required],
      shirt_number: ["", Validators.required],
      shirt_size: ["", Validators.required],
      event_id: [this.utilService.event.id],
    });
  }
  createFormPayment() {
    this.createPayment = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      ddd: ["", Validators.required],
      phone: ["", Validators.required],
      documentNumber: ["", Validators.required],
      born_at: ["", Validators.required],
      amount: [""],
      holder_name: ["", Validators.required],
      state: ["", Validators.required],
      city: ["", Validators.required],
      neighborhood: ["", Validators.required],
      street: ["", Validators.required],
      street_number: ["", Validators.required],
      zipcode: ["", Validators.required],
      card_number: ["", Validators.required],
      card_cvv: ["", Validators.required],
      card_expiration: ["", Validators.required],
      num_part: ["", Validators.required],
      complementary: [""],
      cupom: [""],
    });
  }
  /*========================================================================----- Fazer aparecer em tempo real o que for digitado */
  liberaBotao(libera) {
    this.liberabotao = libera;
  }
  realtimename(event) {
    this.customName = event.value;
  }
  realtimenumber(event) {
    this.customNumber = event.value * 1;
  }
  getPlayerName(event) {
    this.playerName = event.value;
  }
  /*==================================================================================================================----- GETS */
  getTeamByGameId() {
    this.teamService.getTeamByGameId(this.utilService.game).subscribe(
      (res) => {
        this.teams = res;
        this.verificaPlayers(this.teams);
      },
      (error) => {}
    );
  }
  getQuantityPositions(quantity) {
    this.quantity = quantity;
    if (quantity == 6) {
      alert(
        "Para adquirir essa quantidade de vouchers, entre em contato com a nossa central"
      );
    }
  }
  /*============================================================================================================----- Creat Clients */
  addClient() {
    const object = this.createObject(
      this.createClient.value,
      this.position_id,
      this.teams
    );
    if (this.packagePurchase.length < this.packagePlayer.length) {
      this.packagePurchase.push(object);
    } else {
      this.purchaseButton = 1;
    }
    if (this.packagePurchase.length == this.packagePlayer.length) {
      this.purchaseButton = 1;
    }
  }
  createObject(form, position, team) {
    return {
      form: form,
      position_id: position,
      team: team,
    };
  }
  zerarNumberOk() {
    this.numberOk = 0;
  }
  setExistNumber(customNumber) {
    this.customNumber = customNumber;
    this.customNumber = this.customNumber * 1;
    this.customNumber = this.customNumber + "";
    this.checkExistNumber();
  }
  checkExistNumber() {
    this.numberOk = 0;
    if (this.teamSide == 0) {
      if (this.teams.players.length > 0) {
        for (let num = 0; num < this.teams.players.length; num++) {
          if (this.customNumber == this.teams.players[num].shirt_number) {
            this.numberOk = 0;
            alert("Este número já está ocupado. Por favor, escolha outro.");
            return 1;
          } else {
            this.numberOk = 1;
          }
        }
      }
    }

    if (this.teamSide == 1) {
      if (this.teams.players.length > 0) {
        for (let num = 0; num < this.teams.players.length; num++) {
          if (this.customNumber == this.teams.players[num].shirt_number) {
            this.numberOk = 0;
            alert("Este número já está ocupado. Por favor, escolha outro.");
            return 1;
          } else {
            this.numberOk = 1;
          }
        }
      }
    }
    this.numberOk = 1;
    return 1;
  }
  setPlayerConfig(arrayPlayer) {
    this.createFormClient();
    this.createFormPayment();
    this.formType = 1;
    this.position_name = arrayPlayer.position.description;
    this.team_name = arrayPlayer.team.name;
    this.position_id = arrayPlayer.position.id;
    this.teamSide = arrayPlayer.team_side;
    this.teams = arrayPlayer.team;
    this.getPackagePrice();
    this.team_id = arrayPlayer.team.id;
    this.getHour(arrayPlayer.team);
    $("#list-tab li:first-child a").tab("show");
  }

  getPackagePrice() {
    this.packagePriceService
      .getPackagePriceByEvent(this.utilService.event.id)
      .subscribe((res) => {
        for (let item of res) {
          if (this.position_id == 1 && item.position_id == 1) {
            this.positionValue = item.value;

            if (
              this.positionValue == undefined ||
              this.positionValue == null ||
              this.positionValue == []
            ) {
              this.position_value = "1320,00";
              this.position_value_modal = 1320;
              this.goal_keeper_value = this.position_value;
            } else {
              this.positionValue = parseFloat(this.positionValue);
              this.position_value = parseFloat(this.position_value);

              this.position_value = this.positionValue
                .toString()
                .replace(".", ",");
              this.position_value_modal = this.positionValue;
              this.goal_keeper_value = this.position_value;
            }

            break;
          } else if (this.position_id > 1 && item.position_id > 1) {
            this.positionValue = item.value;

            if (
              this.positionValue == undefined ||
              this.positionValue == null ||
              this.positionValue == []
            ) {
              this.position_value = "1990,00";
              this.position_value_modal = 1990;
              this.line_player_value = this.position_value;
            } else {
              this.positionValue = parseFloat(this.positionValue);
              this.position_value = parseFloat(this.position_value);

              this.position_value = this.positionValue
                .toString()
                .replace(".", ",");
              this.position_value_modal = this.positionValue;
              this.line_player_value = this.position_value;
            }

            break;
          }
        }
      });
  }

  calculateValue() {
    this.goal_keeper_total = 0;
    this.totalValue = 0;
    for (let i = 0; i < this.packagePurchase.length; i++) {
      if (this.packagePurchase[i].position_id == 1) {
        this.goalKeeper();
      } else {
        this.linePlayer();
      }
      this.value();
    }
  }

  createClients() {
    // this.checkPosition();
    if (this.reserved_position.length == 0) {
      $("#registerModal").modal("hide");
      $("#waitModal").modal("show");
      this.preparePayment();
    }
  }

  goalKeeper() {
    this.partial_value = this.goal_keeper_value;
    this.goal_keeper_total =
      parseFloat(this.goal_keeper_total) + parseFloat(this.goal_keeper_value);
  }

  linePlayer() {
    this.partial_value = this.line_player_value;
  }

  value() {
    this.totalValue = parseFloat(this.totalValue);
    this.partial_value = parseFloat(this.partial_value);
    this.totalValue = this.totalValue + this.partial_value;
  }

  confirmMask(value, mask) {
    value = value.replace(/[^0-9]/g, "");
    if (mask === "cpf") return this.cpf(value);
    if (mask === "phone") return this.phone(value);
    if (mask === "cep") return this.cep(value);
    if (mask === "card_number") return this.cardNumber(value);
    if (mask === "expiration_date") return this.expirationDate(value);
    if (mask === "security_code") return this.securityCode(value);
  }

  cpf(value) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  phone(value) {
    return value.replace(/(\d{5})(\d{4})/g, "$2-$3");
  }

  cep(value) {
    value.replace(/(\d{5})(\d{3})/g, "$1-$2");
    this.searchCEP(value);
  }

  cardNumber(value) {
    return value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, "$1 $2 $3 $4");
  }

  expirationDate(value) {
    return value.replace(/(\d{2})(\d{2})/g, "$1/$2");
  }

  securityCode(value) {
    return value.replace(/(\d{4})/g, "$1");
  }

  searchCEP(value) {
    value.replace(/[^0-9]/g, "");
    this.utilService.searchCEP(value).subscribe((response) => {
      let resp: any;
      resp = response;
      if (response) {
        this.createPayment.patchValue({
          state: resp.uf,
          city: resp.localidade,
          neighborhood: resp.bairro,
          street: resp.logradouro,
        });
      } else {
        alert(`CEP não encontrado!`);
      }
    });
  }

  getDiscount() {
    this.totalValue = 0
    this.utilService
      .show(this.table, this.utilService.event.id)
      .subscribe((data) => {
        let position = 0;
        for (let item of data) {
          if (
            item.code.toUpperCase() ===
            this.createPayment.value.cupom.toUpperCase()
          ) {
            if (
              this.date >= item.start_date &&
              this.date <= item.expiration_date
            ) {
              position++;
              this.packagePurchase.forEach((element) => {
                let partial = 0;
                let discount = 0;
                if (element.position_id == 1) {
                  discount = item.goal_keeper_value;
                  partial = this.goal_keeper_value;
                } else {
                  discount = item.line_player_value;
                  partial = this.line_player_value;
                }

                this.totalValue += partial - discount;
              });

              console.log(this.totalValue)
              // this.totalValue = this.totalValue - this.goal_keeper_total;
              // if (this.totalValue > 0) {
              //   // this.totalValue = this.totalValue - (parseFloat(this.totalValue) * parseFloat(item.value) / 100) // Versão porcentagem;
              //   this.totalValue = this.totalValue - parseFloat(item.value); // Versão em valor
              // }
              // this.totalValue = this.totalValue + this.goal_keeper_total;
              this.block_cupon = 1;
              alert("Cupom aplicado");
              break;
            }
          }
        }

        if (position === 0) {
          alert("Cupom inválido");
        }
      });
  }

  getAge() {
    let date = this.date.split(" ")[0];
    date = date.split("-");

    let ano_atual = date[0];
    let mes_atual = date[1];
    let dia_atual = date[2];

    let born_at = this.createPayment.value.born_at.split("-");
    let ano_aniversario = born_at[0];
    let mes_aniversario = born_at[1];
    let dia_aniversario = born_at[2];

    let quantos_anos = ano_atual - ano_aniversario;

    if (
      mes_atual < mes_aniversario ||
      (mes_atual == mes_aniversario && dia_atual < dia_aniversario)
    ) {
      quantos_anos--;
    }

    this.age = quantos_anos < 0 ? 0 : quantos_anos;
    if (this.age < 16) {
      this.createPayment.controls["born_at"].setValue(null);
      alert(
        "Para realizar a compra você precisa ter no mínimo 16 anos de idade"
      );
    }
  }

  preparePayment() {
    if (this.createPayment.value.complementary == "") {
      this.createPayment.value.complementary = "N/D";
    }
    this.createPayment.value.payment = 0;
    this.createPayment.value.card_expiration = this.maskService.retiraMascara(
      this.createPayment.value.card_expiration
    );
    this.createPayment.value.zipcode = this.maskService.retiraMascara(
      this.createPayment.value.zipcode
    );
    this.createPayment.value.card_number = this.maskService.retiraMascara(
      this.createPayment.value.card_number
    );
    this.createPayment.value.documentNumber = this.maskService.retiraMascara(
      this.createPayment.value.documentNumber
    );
    this.createPayment.value.phone = this.maskService.retiraMascara(
      this.createPayment.value.phone
    );

    this.sendPayment();
  }

  sendPayment() {
    this.createPayment.value.event_id = this.utilService.event.id;
    this.createPayment.value.documentNumber = this.maskService.retiraMascara(
      this.createPayment.value.documentNumber
    );
    this.createPayment.value.amount = this.totalValue * 100;

    let register = {
      client: this.createPayment.value,
      players: this.packagePurchase,
    };

    this.clientService.addPagarme(register).subscribe(
      (res) => {
        location.replace("https://diadecraque.com.br/obrigado.html");
        // location.replace("https://convite.diadecraque.com.br/obrigado.html");
        // location.replace(
        //   "http://arenaexperience.quatervoistecnologia.com.br/obrigado.html"
        // );
        // location.replace("http://localhost:4200/obrigado.html");

        $("#waitModal").modal("close");
      },
      (error) => {
        alert("Erro no cadastro entre em contato pelo telefone");
      }
    );
  }
  /*==============================================================================================----- Lead que cancelou cadastro */
  createLeads() {
    if (
      this.createPayment.value.name != "" &&
      this.createPayment.value.phone != "" &&
      this.createPayment.value.email != "" &&
      this.createPayment.value.documentNumber != ""
    ) {
      this.createPayment.value.phone = this.maskService.retiraMascara(
        this.createPayment.value.phone
      );
      this.createPayment.value.documentNumber = this.maskService.retiraMascara(
        this.createPayment.value.documentNumber
      );
      this.createPayment.value.event_id = this.utilService.event.id;
      this.leadService.add(this.createPayment.value).subscribe(
        (resp) => {},
        (err) => {}
      );
    }
  }
  /*==============================================================================================----- VALIDORES*/
  validaCpf(cpf) {
    let res;
    cpf = this.maskService.retiraMascara(cpf);
    res = this.validatorsService.testaCPF(cpf);
    if (res == true) {
      this.fcpf = true;
    } else {
      alert("CPF INVÁLIDO");
      this.createClient.value.documentNumber = "";
      this.fcpf = false;
    }
  }
  getHour(hour) {
    this.gameHour = hour.game.schedule;
  }
  verificaPlayers(teams) {
    (this.playerSoccer = teams[0].players.length),
      (this.playerSoccer2 = teams[1].players.length);
  }
  checkPosition() {
    this.teamService.getTeamByGameId(this.gameId).subscribe(
      (res) => {
        this.teams = res;
        if (
          this.teams[0].players.length > 0 ||
          this.teams[1].players.length > 0
        ) {
          for (let tp of this.teams[0].players) {
            for (let pp of this.packagePurchase) {
              if (
                pp.team.id == tp.team_id &&
                pp.position_id == tp.position_id
              ) {
                this.reserved_position.push(pp);
              }
            }
          }
          for (let tp of this.teams[1].players) {
            for (let pp of this.packagePurchase) {
              if (
                pp.team.id == tp.team_id &&
                pp.position_id == tp.position_id
              ) {
                this.reserved_position.push(pp);
              }
            }
          }
          if (this.reserved_position.length > 0) {
            alert(
              "Ops! Alguém acabou de comprar uma ou mais posições da sua seleção"
            );
            location.replace("https://www.diadecraque.com.br/");
            // location.replace("https://convite.diadecraque.com.br/");
            // location.replace(
            //   "http://arenaexperience.quatervoistecnologia.com.br"
            // );
            // location.replace("http://localhost:4200");
          }
        } else {
          console.log("siga em frente");
        }
      },
      (error) => {}
    );
  }

  closeModal() {
    this.packagePlayer = [];
  }
}
