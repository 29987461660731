import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs";
import { UtilService } from "../util/util.service";

@Injectable({
  providedIn: "root"
})
export class PackagePriceService {
  private url: any;

  constructor(private http: HttpClient, private utilService: UtilService) {
    this.url = utilService.getUrl();
  }

  getPackagePrice(): Observable<any> {
    return this.http.get(this.url + "package");
  }

  getPackagePriceByEvent(event): Observable<any> {
    return this.http.get(this.url + "package/event/" + event);
  }
}
