import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs';
import { UtilService } from '../util/util.service';


@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private url: any;

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) {
    this.url = utilService.getUrl();
  }

  contactUs(message): Observable <any > {
    return this.http.post(this.url + 'contato',message);
  }
}
