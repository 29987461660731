import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  sharePackagePlayer = new EventEmitter();

  constructor() { }

  packagePlayer(packagePlayer){
    this.sharePackagePlayer.emit(packagePlayer);
  }
}
