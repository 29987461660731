import { Component, OnInit } from "@angular/core";
import { UtilService } from "../util/util.service";

@Component({
  selector: "app-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.css"],
})
export class TopBarComponent implements OnInit {
  public voucherHtml = 0;
  public cpf: any = "";

  constructor(public utilService: UtilService) {}

  ngOnInit() {}
  clearCpf() {
    this.cpf = [];
    this.voucherHtml = 0;
  }
}
