import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs';


import { UtilService } from '../util/util.service';

@Injectable({
    providedIn: 'root'
})

export class EventService {

    private url :any;

    constructor(
        private http: HttpClient,
        private utilService: UtilService
    ) { 
        this.url = utilService.getUrl();
    }

    getEvent(): Observable <any> {
        return this.http.get(this.url + 'event/active');
    }
    getVoucherByClientId(client_id): Observable <any> {
        return this.http.get(this.url + 'team/' + client_id );
    }
}
