import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs";

import { UtilService } from "../util/util.service";

@Injectable({
  providedIn: "root"
})
export class LeadService {
  private url: any;

  constructor(private http: HttpClient, private utilService: UtilService) {
    this.url = utilService.getUrl();
  }

  get(): Observable<any> {
    return this.http.get(this.url + "lead");
  }

  add(data): Observable<any> {
    return this.http.post(this.url + "lead", data);
  }

  delete(data): Observable<any> {
    return this.http.post(this.url + "lead/delete", data);
  }
}
