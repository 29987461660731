import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2OrderModule } from "ng2-order-pipe";

import { ClientsService } from "./clients/clients.service";
import { LeadService } from "./lead/lead.service";
import { VoucherService } from "./modal/voucher/voucher.service";
import { GameService } from "./game/game.service";
import { PositionService } from "./position/position.service";
import { UtilService } from "./util/util.service";
import { EmailService } from "./email/email.service";
import { RowService } from "./modal/row/row.service";
import { PackagePriceService } from "./package_price/package_price.service";
import { MaskService } from "./util/mask.service";
import { ValidatorsService } from "./util/validators.service";

import { BannerComponent } from "./banner/banner.component";
import { ExperienceComponent } from "./experience/experience.component";
import { ExperienceSummaryComponent } from "./experience-summary/experience-summary.component";
import { SoccerFieldComponent } from "./soccer-field/soccer-field.component";
import { FooterComponent } from "./footer/footer.component";
import { RegisterComponent } from "./modal/register/register.component";
import { RegulationComponent } from "./modal/regulation/regulation.component";
import { VoucherComponent } from "./modal/voucher/voucher.component";
import { LoadComponent } from "./load/load.component";
import { TopBarComponent } from "./top-bar/top-bar.component";
import { CounterComponent } from "./counter/counter.component";
import { ContactFormFooterComponent } from "./contact-form-footer/contact-form-footer.component";
import { AppComponent } from "./app.component";
import { RowComponent } from "./modal/row/row.component";
import { ClientsComponent } from "./clients/clients/clients.component";
import { AlertComponent } from "./modal/alert/alert.component";
import { WaitComponent } from "./modal/wait/wait.component";
import { MedicalRecordComponent } from "./modal/medical-record/medical-record.component";
import { PostponementAlertComponent } from "./modal/postponement-alert/postponement-alert.component";

import { LOCALE_ID } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    RowComponent,
    ClientsComponent,
    BannerComponent,
    ExperienceComponent,
    ExperienceSummaryComponent,
    SoccerFieldComponent,
    FooterComponent,
    RegisterComponent,
    RegulationComponent,
    LoadComponent,
    VoucherComponent,
    TopBarComponent,
    CounterComponent,
    ContactFormFooterComponent,
    AlertComponent,
    WaitComponent,
    MedicalRecordComponent,
    PostponementAlertComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2OrderModule,
  ],
  providers: [
    ClientsService,
    LeadService,
    VoucherService,
    GameService,
    PositionService,
    UtilService,
    RowService,
    PackagePriceService,
    EmailService,
    MaskService,
    ValidatorsService,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR",
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
