import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs';
import { UtilService } from '../util/util.service';


@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  
  private url :any;
  
  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { 
    this.url = utilService.getUrl();
  }

  get(): Observable <any> {
    return this.http.get(this.url + 'player');
  }
  getPlayerById(id): Observable <any> {
    return this.http.get(this.url + 'player/unique/' + id);
  }
  getPlayerByTeamId(team_id): Observable <any> {
    return this.http.get(this.url + 'player/' + team_id);
  }
  getPlayerByEventAndUuid(event, uuid): Observable <any> {
    return this.http.get(this.url + 'player/' + 'uuid/' + uuid + '/event/' + event);
  }
  getPlayerByUuid(uuid): Observable <any> {
    return this.http.get(this.url + 'player/' + 'uuid/' + uuid);
  }
  update(data): Observable < any > {
    return this.http.put(this.url + 'player/' + data.id, data);
  }
  add(data): Observable <any> {
    return this.http.post(this.url + 'player', data);
  }
}
