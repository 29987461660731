import { Component, OnInit } from "@angular/core";
import { EventService } from "../event/event.service";
import { GameService } from "../game/game.service";
import { TeamService } from "../team/team.service";
import { PlayerService } from "../player/player.service";
import { PositionService } from "../position/position.service";
import { ShareService } from "../share/share.service";
import { UtilService } from "src/app/util/util.service";

declare const $: any;

@Component({
  selector: "app-soccer-field",
  templateUrl: "./soccer-field.component.html",
  styleUrls: ["./soccer-field.component.css"],
})
export class SoccerFieldComponent implements OnInit {
  public message: any;
  title = "app";

  public client: any;
  public events: any = 0;
  public games: any = 0;
  public teams: any;
  public players: any;
  public player: any;
  public position: any;
  public position_id: any;
  public client_id: any;
  public team_id: any;
  public numberOk = 0;
  public position_name: any;
  public team_name: any;
  public eventId: any;
  public gameId: any;
  public showForm = 1;
  public playerSoccer: any;
  public playerSoccer2: any;
  public selected_game: any;
  // compra de várias posições
  public quantity: any;
  public quantitySelected = 0;
  public arrayPlayer = [];
  public packagePlayer = [];
  public formType = 0;
  // compra de várias posições
  public full_team_A: any = [];
  public empty_team_A: any = [];
  public full_team_B: any = [];
  public empty_team_B: any = [];

  constructor(
    private eventService: EventService,
    private gameService: GameService,
    private teamService: TeamService,
    private playerService: PlayerService,
    private positionService: PositionService,
    private shareService: ShareService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.numberOk = 1;
    this.teams = [];
    this.players = [];
    this.getEvent();
    this.getPosition();
  }

  /*==================================================================================================================----- GETS */
  activegreenshirt(event, position_id) {
    if (position_id > 1 && position_id < 12) {
      event.target.classList.toggle("activeshirt");
    } else if (position_id == 1) {
      event.target.classList.toggle("actvegol1");
    }
  }

  activewhiteshirt(event, position_id) {
    if (position_id > 12 && position_id < 24) {
      event.target.classList.toggle("activeshirt2");
    } else if (position_id == 12) {
      event.target.classList.toggle("actvegol2");
    }
  }

  activegolshirt(event, position_id) {
    if (position_id == 1) {
      event.target.classList.toggle("actvegol1");
    } else if (position_id == 12) {
      event.target.classList.toggle("actvegol2");
    }
  }

  /*==================================================================================================================----- GETS */
  getEvent() {
    this.eventService.getEvent().subscribe(
      (res) => {
        this.events = res;
        this.utilService.setEvent(this.events[this.events.length - 1]);
        this.utilService.setEventDate(this.events[this.events.length - 1].date);
        this.getGameByEventId(this.events[this.events.length - 1].id);
      },
      (error) => {}
    );
  }
  getPosition() {
    this.positionService.getAll().subscribe(
      (res) => {
        this.position = res;
        // this.openFilaEspera();
      },
      (error) => {}
    );
  }
  getGameByEventId(event_id) {
    this.games = [];
    this.teams = [];
    this.eventId = event_id;
    this.gameService.getGameByEventId(event_id).subscribe(
      (res) => {
        this.games = res;
        for (let row of this.games) {
          if (row.default_game == 1) {
            this.selected_game = row;
            this.getTeamByGameId(row.id);
          }
        }
      },
      (error) => {}
    );
  }
  getTeamByGameId(id) {
    this.gameId = id;
    this.utilService.setGame(id);
    this.teamService.getTeamByGameId(id).subscribe(
      (res) => {
        this.teams = res;
        this.verificaPlayers(this.teams);
      },
      (error) => {}
    );
  }

  getPlayerByTeamId(id) {
    this.playerService.getPlayerByTeamId(id).subscribe(
      (res) => {
        this.players = res;
      },
      (error) => {}
    );
  }
  getQuantityPositions(quantity) {
    this.quantity = quantity;
    if (quantity == 6) {
      $("#alertModal").modal("show");
      // alert('Para adquirir essa quantidade de vouchers, entre em contato com a nossa central');
    }
  }
  /*===========================================================================================----- Abrir modal fila de espera */
  openFilaEspera() {
    $("#esperaModal").modal("show");
  }
  /*============================================================================================================----- Creat Clients */
  zerarNumberOk() {
    this.numberOk = 0;
  }
  createObject(position, team, teamSide) {
    return {
      position_name: position.description,
      position: position,
      team: team,
      team_name: team.name,
      position_id: position.id,
      team_id: team.id,
      game_hour: team.game.schedule,
      team_side: teamSide,
    };
  }
  containsObject(obj, list) {
    return list.some((elem) => JSON.stringify(elem) == JSON.stringify(obj));
  }
  removeObjFromArray(obj, list) {
    return list.filter((elem) => JSON.stringify(elem) != JSON.stringify(obj));
  }
  setPlayerConfig(position, team, teamSide) {
    if (
      this.quantity == undefined ||
      this.quantity == null ||
      this.quantity == ""
    ) {
      alert("Por favor, selecione quantas posições deseja comprar");
    }
    if (this.quantity >= 1 && this.quantity < 6) {
      let object = this.createObject(position, team, teamSide);

      if (this.containsObject(object, this.packagePlayer)) {
        this.packagePlayer = this.removeObjFromArray(
          object,
          this.packagePlayer
        );
        this.quantitySelected--;
      } else {
        if (this.packagePlayer.length < this.quantity) {
          this.packagePlayer.push(object);
          this.quantitySelected++;
        } else {
          alert("Número máximo de posições selecionadas");
        }
      }
      if (this.quantitySelected == this.quantity) {
        this.shareService.packagePlayer(this.packagePlayer);
        $("#registerModal").modal("show");
        this.zerarPosition();
      }
    }
    if (this.quantity == 6) {
      $("#alertModal").modal("show");
    }
  }
  verificaPlayers(teams) {
    (this.playerSoccer = teams[0].players.length),
      (this.playerSoccer2 = teams[1].players.length);
  }
  zerarPosition() {
    this.quantitySelected = 0;
    this.packagePlayer = [];
  }
}
