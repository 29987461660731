import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs';

import { UtilService } from '../util/util.service';

@Injectable({
    providedIn: 'root'
})

export class ClientsService {

    private url :any;

    constructor(

        private http: HttpClient,
        private utilService: UtilService

    ) {
        this.url = utilService.getUrl();
     }

    get(): Observable <any> {
        return this.http.get(this.url + 'client/active');
    }
    getEvendIdAndCpf(event_id, cpf): Observable <any> {
        return this.http.get(this.url + 'voucher/search/' + cpf + '/event/' + event_id);
    }
    getCpf(cpf): Observable <any> {
        return this.http.get(this.url + 'voucher/search/' + cpf);
    }
    add(data): Observable <any> {
        return this.http.post(this.url + 'client', data);
    }
    addPagarme(data): Observable <any> {
        return this.http.post(this.url + 'pagarme', data);
    }

    creatTransaction(data): Observable <any> {
        return this.http.post(this.url + 'pagarme', data);
    }
}
