import { Component, OnInit } from "@angular/core";
import { ClientsService } from "../../clients/clients.service";
import { VoucherService } from "./voucher.service";
import { MaskService } from "../../util/mask.service";
import { ValidatorsService } from "../../util/validators.service";
import { PlayerService } from "../../player/player.service";
import { EventService } from "../../event/event.service";

declare const $: any;

@Component({
  selector: "app-voucher",
  templateUrl: "./voucher.component.html",
  styleUrls: ["./voucher.component.css"]
})
export class VoucherComponent implements OnInit {
  public voucher: any = [];
  public vouchers: any = [];
  public voucherHtml = 0;
  public printButton = 0;
  public cpf: any = "";
  public fcpf: any = false;
  public search_by: any;
  public code: any;
  public player: any;
  public upPlayer: any = 0;
  public medicalRecordPlayer: any = 0;
  public events: any = [];
  public event = null;

  constructor(
    private voucherService: VoucherService,
    private maskService: MaskService,
    private validatorsService: ValidatorsService,
    private clientService: ClientsService,
    private playerService: PlayerService,
    private eventService: EventService,
  ) {}

  ngOnInit() {
    // this.clearCpf();
    this.getEvents();
  }

  getEvents() {
    let today;
    let dNow = new Date();
    today = dNow.getFullYear() + '-' + (dNow.getMonth() + 1 < 10 ? "0" : "") + (dNow.getMonth() + 1) + "-" + (dNow.getDate() < 10 ? "0" : "") + dNow.getDate();
    this.eventService.getEvent().subscribe(
      (res) => {
        for(let item of res){
          if(item.date > today){
            this.events.push(item);
          }
        }
        if(this.events.length == 1){
          this.setEvent(this.events[0].id);
        }
      },
      (error) => {}
    );
  }

  getVoucher() {
    if (this.search_by == 1) {
      this.voucher = [];
      // $('#preloader').css('display', 'block');
      this.cpf = this.maskService.retiraMascara(this.cpf);
      let res = this.validatorsService.testaCPF(this.cpf);
      if (res == true) {
        this.clientService.getEvendIdAndCpf(this.event, this.cpf).subscribe(
          res => {
            if (res.length > 0) {
              this.vouchers = res;
              this.voucherHtml = 1;
              this.printButton = 1;
            } else {
              alert("Você não tem nenhum CPF cadastrado");
              // $('#preloader').css('display', 'none');
              $(".slicknav_nav").css("display", "none");
            }

            // $('#preloader').css('display', 'none');
          },
          error => {
            // $('#preloader').css('display', 'none');
          }
        );
      } else {
        alert("CPF INVÁLIDO");
        // $('#preloader').css('display', 'none');
      }
    } else if (this.search_by == 2) {
      this.playerService.getPlayerByEventAndUuid(this.event, this.code).subscribe(res => {
        if (res == undefined) {
          alert("Não possui voucher com esse código");
        } else {
          this.getVouchersByPlayerId(res.id);
        }
      });
    }
  }

  getVouchersByPlayerId(player_id) {
    this.voucherService.getVoucherByPlayerId(player_id).subscribe(
      res => {
        if (res.length == 0) {
          alert("Não possui voucher com esse código");
        } else {
          this.vouchers = res;
          this.voucherHtml = 1;
          this.printButton = 1;
          $("#preloader").css("display", "none");
        }
      },
      error => {}
    );
  }
  clearCpf() {
    this.cpf = [];
    this.code = [];
    this.search_by = 0;
    this.voucherHtml = 0;
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
        <html>
          <head>
            <title>Print tab</title>
            <style>
            //........Customized style.......
            </style>
          </head>
      <body onload="window.print();window.close()">${printContents}</body>
        </html>`);
    popupWin.document.close();
  }
  validaCpf(cpf) {
    let res;
    cpf = this.maskService.retiraMascara(cpf);
    res = this.validatorsService.testaCPF(cpf);
    if (res == true) {
      this.fcpf = true;
    } else {
      alert("CPF INVÁLIDO");
      // this.createClient.value.documentNumber = '';
      this.fcpf = false;
    }
  }

  medicalRecord() {
    this.medicalRecordPlayer = 1;
  }
  setPlayer(player) {
    this.player = player;
    this.upPlayer = 1;
  }
  setEvent(value){
    this.event = value;
  }
}
