import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
import { UtilService } from '../../../util/util.service';
// import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class MedicalRecordService {

  private api_url: string;

  constructor(

    private util: UtilService,
    private httpClient: HttpClient

  ) {
    this.api_url = util.getUrl();
  }

  getFields(): any {
    return this.httpClient.get(this.api_url + 'medical_record/fields');
  }

  create(data): any {
    return this.httpClient.post(this.api_url + 'medical_record', data);
  }
}