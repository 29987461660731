import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  public voucherHtml = 0;
  public cpf: any = '';

  constructor() { }

  ngOnInit() {
  }
  clearCpf() {
    this.cpf = [];
    this.voucherHtml = 0;
  }
}
