import { Component, OnInit } from "@angular/core";
import { PackagePriceService } from "../package_price/package_price.service";
import { UtilService } from "../util/util.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

declare const $: any;

@Component({
  selector: "app-experience",
  templateUrl: "./experience.component.html",
  styleUrls: ["./experience.component.css"],
})
export class ExperienceComponent implements OnInit {
  sanitizedVideoUrl: SafeResourceUrl | null = null;
  public positionValue: any;
  public position_value_modal: any;

  constructor(
    private packagePriceService: PackagePriceService,
    private sanitizer: DomSanitizer,
    public utilService: UtilService
  ) {}

  ngOnInit() {
    this.packagePriceService.getPackagePrice().subscribe((res) => {
      for (let item of res) {
        if (item.position_id > 1) {
          this.positionValue = item.value;
        }
      }
      if (
        this.positionValue == undefined ||
        this.positionValue == null ||
        this.positionValue == []
      ) {
        this.position_value_modal = 1790;
      } else {
        this.position_value_modal = this.positionValue;
      }
    });

    setTimeout(() => {
      const videoUrl = this.utilService.event.video_url; // Simulação de busca da URL
      this.updateVideoUrl(videoUrl);
    }, 500);
  }

  updateVideoUrl(url: string | null): void {
    if (url) {
      this.sanitizedVideoUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      this.sanitizedVideoUrl = null;
    }
  }

  modalAdiamento() {
    $("#postponementAlertModal").modal("show");
  }
}
