import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MaskService {

  constructor() { }

   retiraMascara(str) {
    str = str.toString();
    return str.replace(/[^0-9]/g, '');
  }
}
