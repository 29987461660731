import { Component, OnInit, AfterViewInit } from "@angular/core";
import { environment } from "../environments/environment";

declare const $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  public message: any;
  public desktop = 1;
  title = "app";

  constructor() {
    var windowWidth = window.innerWidth;
    var screenWidth = screen.width;
    if (windowWidth <= 768 || screenWidth <= 768) {
      this.desktop = 0;
    } else {
      this.desktop = 1;
    }
  }

  ngOnInit() {
    this.checkNavigator();
    // if (environment.production && location.protocol == "http:") {
    //   location.replace("https://www.diadecraque.com.br/");
    // location.replace("https://convite.diadecraque.com.br/");
    // }
  }
  ngAfterViewInit() {
    setTimeout(function () {
      // $("#postponementAlertModal").modal("show");
      // $("#esperaModal").modal("show");
    }, 1000);
  }
  checkNavigator() {
    let browser;
    let nav = navigator.userAgent.toLowerCase();
    if (nav.indexOf("msie") !== -1) {
      browser = "msie";
      alert("Recomendamos usar os navegadors Chrome ou Safari");
    } else if (nav.indexOf("opera") !== -1) {
      browser = "opera";
      alert("Recomendamos usar os navegadors Chrome ou Safari");
    } else if (nav.indexOf("mozilla") !== -1) {
      if (nav.indexOf("firefox") !== -1) {
        browser = "firefox";
        alert("Recomendamos usar os navegadors Chrome ou Safari");
      } else if (nav.indexOf("firefox") !== -1) {
        browser = "mozilla";
        alert("Recomendamos usar os navegadors Chrome ou Safari");
      } else if (nav.indexOf("chrome") !== -1) {
        browser = "chrome";
      }
    } else {
      alert("Navegador desconhecido!");
      alert("Recomendamos usar os navegadors Chrome ou Safari");
    }
  }
}
