import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { PlayerService } from "../../player/player.service";
import { MedicalRecordService } from "./service/medical-record-service.service";
declare const $: any;

@Component({
  selector: "app-medical-record",
  templateUrl: "./medical-record.component.html",
  styleUrls: ["./medical-record.component.css"]
})
export class MedicalRecordComponent implements OnInit, OnChanges {
  form: FormGroup;
  updatePlayerName: FormGroup;
  @Input() player;
  @Output() refreshTable = new EventEmitter();
  public options;
  public medical_record: any;
  public player_updated: any;

  constructor(
    private formBuilder: FormBuilder,
    private medicalRecordService: MedicalRecordService,
    private playerService: PlayerService
  ) {}

  ngOnInit() {
    this.getOptions();
    this.medicalRecord();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.medicalRecord();
  }

  getOptions() {
    this.medicalRecordService.getFields().subscribe(resp => {
      this.options = resp;
    });
  }

  medicalRecord() {
    this.form = this.formBuilder.group({
      date: ["", Validators.required],
      name: ["", Validators.required],
      born_at: ["", Validators.required],
      sedentary: ["", Validators.required],
      physical_activity: ["", Validators.required],
      smoker: ["", Validators.required],
      time_cigar: ["", Validators.required],
      amount_cigar: ["", Validators.required],
      symptoms_during_sport: ["", Validators.required],
      health_problem: ["", Validators.required],
      family_health_problem: ["", Validators.required],
      sudden_death: ["", Validators.required],
      injuries: ["", Validators.required],
      treatment: ["", Validators.required],
      supplement: ["", Validators.required],
      player_id: [this.player.id, Validators.required]
    });
  }

  createRecord() {
    this.medicalRecordService.create(this.form.value).subscribe(resp => {
      this.medical_record = resp;
      this.refreshTable.emit();
      this.updatePlayer(this.medical_record);
      this.medicalRecord();
      alert("Ficha médica enviada com sucesso");
      $("#MedicalRecordModal").modal("hide");
    });
  }

  updatePlayer(resp) {
    this.updatePlayerName = this.formBuilder.group({
      id: [this.player.id, Validators.required],
      name: [resp.name, Validators.required]
    });
    this.update();
  }

  update() {
    this.playerService.update(this.updatePlayerName.value).subscribe(resp => {
      this.player_updated = resp;
    });
  }
  // print(): void {
  //   let printContents, popupWin;
  //   printContents = document.getElementById('print-medical-record').innerHTML;
  //   popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  //   popupWin.document.open();
  //   popupWin.document.write(`
  //       <html>
  //         <head>
  //           <title>Print tab</title>
  //           <style>
  //           //........Customized style.......
  //           </style>
  //         </head>
  //     <body onload="window.print();window.close()">${printContents}</body>
  //       </html>`
  //   );
  //   popupWin.document.close();
  // }
}
