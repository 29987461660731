import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { EmailService } from "../email/email.service";

declare const $: any;

@Component({
  selector: "app-contact-form-footer",
  templateUrl: "./contact-form-footer.component.html",
  styleUrls: ["./contact-form-footer.component.css"]
})
export class ContactFormFooterComponent implements OnInit {
  contact: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService
  ) {}

  ngOnInit() {
    this.createContactForm();
  }

  /*===========================================================================================----- ENVIO DE EMAIL */
  sendEmail() {
    $("#preloader").css("display", "block");
    this.emailService.contactUs(this.contact.value).subscribe(
      res => {
        $("#preloader").css("display", "none");
        alert("Agradecemos seu contato, iremos retornar em breve");
        this.createContactForm();
      },
      error => {
        alert(
          "Erro ao enviar mensagem, por favor, verifique se todos os campos estão preenchidos corretamente"
        );
      }
    );
  }

  getDate(){
    return new Date();
  }

  createContactForm() {
    this.contact = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", Validators.required],
      message: ["", Validators.required]
    });
  }
}
