import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class UtilService {
  public api_url = this.getUrl();
  public event = null;
  public event_date = null;
  public game = null;

  constructor(private http: HttpClient) {}

  getUrl() {
    return environment.baseUrl;
  }

  setEvent(value){
    this.event = value;
  }

  setEventDate(value){
    this.event_date = value;
  }

  setGame(value){
    this.game = value;
  }

  get(table, page, limit): any {
    if (page != null && limit == null) {
      return this.http.get(
        this.api_url + table + "?paginate=true&page=" + page
      );
    } else if (limit != null && page != null) {
      return this.http.get(
        this.api_url + table + "?paginate=true&page=" + page + "&limit=" + limit
      );
    } else {
      return this.http.get(this.api_url + table);
    }
  }
  getByEmail(email) {
    return this.http.get(this.api_url + "users/email/" + email);
  }
  getRelashion(table, first, second) {
    return this.http.get(
      this.api_url + table + "/" + first + "/" + second
    );
  }
  show(table, id): any {
    return this.http.get(this.api_url + table + "/" + id);
  }
  showById(table, field, id, page, limit): any {
    return this.http.get(
      this.api_url +
        table +
        "?paginate=true&page=" +
        page +
        "&limit=" +
        limit +
        "&field=" +
        field +
        "&id=" +
        id
    );
  }
  create(table, data): any {
    return this.http.post(this.api_url + table, data);
  }
  update(table, data): any {
    return this.http.put(this.api_url + table + "/" + data.id, data);
  }
  delete(table, data): any {
    return this.http.delete(this.api_url + table + "/" + data.id, data);
  }
  searchCEP(value) {
    return this.http.jsonp(
      `https://viacep.com.br/ws/${value}/json/`,
      "callback=callback_name"
    );
  }
}
