import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { UtilService } from "src/app/util/util.service";
import { RowService } from "./row.service";

declare const $: any;

@Component({
  selector: "app-row",
  templateUrl: "./row.component.html",
  styleUrls: ["./row.component.css"]
})
export class RowComponent implements OnInit {
  createRow: FormGroup;
  public row: any;

  constructor(
    private rowService: RowService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
    this.createFormRow();
  }

  getRow() {
    this.rowService.get().subscribe(
      res => {
        this.row = res;
      },
      error => {}
    );
  }

  createFormRow() {
    this.createRow = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      ddd: ["", Validators.required],
      phone: ["", Validators.required]
    });
  }

  createRows() {
    this.createRow.value.event_id = this.utilService.event.id;
    // $('#preloader').css('display', 'block');
    this.rowService.add(this.createRow.value).subscribe(
      resp => {
        this.getRow();
        alert("Seus dados foram inserido na lista de espera");
        $("#esperaModal").modal("hide");
        // $('#preloader').css('display', 'none');
        this.createFormRow();
      },
      err => {}
    );
  }
}
