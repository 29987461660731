import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-postponement-alert",
  templateUrl: "./postponement-alert.component.html",
  styleUrls: ["./postponement-alert.component.css"]
})
export class PostponementAlertComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
