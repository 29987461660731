import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilService } from '../../util/util.service';
import { Observable } from 'rxjs';
import 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class VoucherService {

    private url: any;

    constructor(

        private http: HttpClient,
        private utilService: UtilService

    ) { 
        this.url = utilService.getUrl();
    }

    get(): Observable <any> {
        return this.http.get(this.url + 'voucher');
    }
    getVoucherByClientId(client_id): Observable <any> {
        return this.http.get(this.url + 'voucher/event/' + client_id );
    }
    getVoucherByPlayerId(player_id): Observable <any> {
        return this.http.get(this.url + 'voucher/by_player/' + player_id );
    }
    add(data): Observable <any> {
        return this.http.post(this.url + 'voucher', data);
    }

}
